module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@6.6.0_babel-plugin-styled-components@2.0.7_styled-components@_56gjsr7joenfncekz65iix2fja/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.6.0_babel-eslint@10.1.0_eslint@8.33.0__react-dom@18.2.0_react@18.2.0__react@18.2.0_t_fy5dvzju4ipzbpxv2oqed25vii/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
